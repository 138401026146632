import '@fontsource-variable/inter'
import '@fontsource/rubik-mono-one'
import '@/root/src/styles/global.scss'

import { useMemo } from 'react'

import { AnimatePresence } from 'framer-motion'
import { AppProps } from 'next/app'
import Head from 'next/head'
import PropTypes from 'prop-types'
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import ThemeProvider from '@/providers/theme'
import { createEmotionCache } from 'shared-utilities'
import {
  CacheProvider,
  EmotionCache,
} from '@emotion/react'
import {
  ErrorBoundary,
  Provider as RollbarProvider,
} from '@rollbar/react'

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

interface MyAppProps extends AppProps {
  emotionCache: EmotionCache
}

const rollbarConfig = {
  accessToken: process.env.NEXT_PUBLIC_ROLLBAR_CLIENT_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: process.env.NODE_ENV === 'production' ? 'production' : 'testenv',
  nodeSourceMaps: true,
}

export default function MyApp(props: MyAppProps) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props
  const queryClient = useMemo(() => new QueryClient(), [])

  return (
    <AnimatePresence mode="wait">
      <RollbarProvider config={rollbarConfig}>
        <ErrorBoundary>
          <CacheProvider value={emotionCache}>
            <Head>
              <title>PAMP Token</title>
              <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1" />
            </Head>
            <QueryClientProvider client={queryClient}>
              <Hydrate state={pageProps.dehydratedState}>
                <ThemeProvider>
                  <Component {...pageProps} />
                  <ReactQueryDevtools initialIsOpen={false} />
                </ThemeProvider>
              </Hydrate>
            </QueryClientProvider>
          </CacheProvider>
        </ErrorBoundary>
      </RollbarProvider>
    </AnimatePresence>
  )
}

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  emotionCache: PropTypes.object,
  pageProps: PropTypes.object.isRequired,
}
